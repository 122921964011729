
._header_3rfbo_1 {
    height: 4.0625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #f8f8f8
}

._header_3rfbo_1 ._logoContainer_3rfbo_14 {
    width: 12.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #007aff
}

._header_3rfbo_1 ._logoContainer_3rfbo_14 img {
    width: 6.25rem;
    height: 1.625rem
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._customerLabel_3rfbo_33 {
    color: #333;
    font-size: .75rem
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37 {
    margin-left: .75rem;
    color: #666
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37:hover {
    color: #0075e4
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37:active {
    color: #005cbf
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37:disabled {
    color: #ddd
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37:focus {
    outline: none
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37:focus-visible {
    color: #666;
    outline: .125rem solid #3395ff;
    outline-offset: -.0625rem
}

._header_3rfbo_1 ._rightSideContainer_3rfbo_27 ._helpLink_3rfbo_37 [class^=icon-] {
    color: inherit;
    font-size: inherit
}
.icon-Resource-Center---Help:before {
    content: "\eae8"
}

.icon-Status-Connected:before {
    content: "\eae9"
}

.icon-Status-Disconnectd:before {
    content: "\eaea"
}

.icon-Status-Happy:before {
    content: "\eaeb"
}

.icon-Status-In-Progress:before {
    content: "\eaec"
}

.icon-Status-Sad:before {
    content: "\eaed"
}

.icon-Action-Custom:before {
    content: "\eabd"
}

.icon-Button-Set:before {
    content: "\eabe"
}

.icon-Chain:before {
    content: "\eabf"
}

.icon-Cloud-Data-Sync:before {
    content: "\eac0"
}

.icon-Cloud:before {
    content: "\eac1"
}

.icon-Convert-Payload:before {
    content: "\eac2"
}
.link_profile{
    display: flex;
    width: 59.852px;
    height: 43.34px;
    justify-content: center;
    align-items: center;
}
.container_profile_page{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0075e4;
    border-radius: 50%;
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}
._container_1ypzn_1 ._link_1ypzn_4{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0075e4;
    border-radius: 50%;
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none; 
}
.header_innovationLab {
    color: #fff;
    flex-shrink: 0;
    font-family: Inter,arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.2px;
    line-height: 13px;
    padding-left: 0;
    text-align: center;
    width: 91px;
}
.top_navigation {
    display: flex;
    width: 830px;
    align-items: flex-start;
    gap: 10px;
}
.top_frame {
    width: 823px;
    height: 41px;
    flex-shrink: 0;
}
.top_rectangle {
    width: 823px;
    height: 3px;
    background: #DDD;
    flex-shrink: 0;
}

.top_navigation {
    display: flex;
    width: 830px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}
.top_frame{
    width: 823px;
    height: 41px;
    flex-shrink: 0;
    margin-left: 10px;
}
.top_rectangle{
    width: 823px;
    height: 3px;
    flex-shrink: 0;
    background: #DDD;
}
.menu_item{
    align-items: flex-start;
    color: #444;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-family: Inter, arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.2px;
    padding-top: 10px;
}

.container {
    margin-top: 30px;
    max-width: 1536px;
}
.page_header{
    height: 65px!important ;
}
div#GivenTimeRow {
    margin-left: -3%;
}
div#appHeaderProfilePageLink{
    padding: 3px;
}
ul#ReasonName-listbox{
    z-index: 12
}
/* body{
    overflow: overlay;
} */