.SystemInfoTable {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.descContainer {
    border: 2px solid #ccc;
    margin: auto;
    width: fit-content;
    font-size: medium;
}

.systemInfoDescription {
    margin-left: 20px;
    margin-right: 20px;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 2px
}

/* Css for SearchableContainer */
.searchField {
    display: none;
}

[data-testid="searchable-container"] {
    border: 0px !important;
    margin: auto;
    padding-left: 1% !important;
    padding-right: 1% !important;
}

@media (min-width: 1700px) {
    [data-testid="searchable-container"] {
        max-width: 51%;
    }
}

/* .table_border {
    border: none;
    border-collapse: collapse;
}
body{
    font-size: 0.9rem;
}
.cell_spacing{
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 40px;
}
.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
}
.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
table {
    border-collapse: collapse;
    width: 80%;
    border: 1px solid lightgrey;
    margin-left: auto;
    margin-right: auto;
}
th,td {
    padding: 8px;
    border-right: 1px solid lightgrey;
}
th {
    padding-right: 30px;
    background-color: lightgray;
    border: 1px solid grey;
    text-align: center; 
}
td {  
    padding-left: 20px;
    text-align: center; 
}
tr{
    border-bottom: 1px solid lightgrey;
}
 */